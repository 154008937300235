import React from "react";
import { motion } from "framer-motion";
export default function Navbar() {
  return (
    <div className="nav">
      <motion.div className="nav_text" whileTap={{ scale: 0.95 }}>
        <h3 className="nav_logo">THIÊNLÔI</h3>
      </motion.div>
      <a href="#platform">
        <motion.h4 className="nav_text" whileTap={{ scale: 0.95 }}>
          Nền tảng
        </motion.h4>
      </a>
      <a href="#contact">
        <motion.h4 className="nav_text" whileTap={{ scale: 0.95 }}>
          Liên hệ
        </motion.h4>
      </a>
      <motion.div>
        <img
          src="logo.png"
          width={80}
          height={80}
          style={{ borderRadius: 20 }}
        />
      </motion.div>
    </div>
  );
}
