import { motion } from "framer-motion";
import { useState } from "react";

const _services = [
  [
    {
      img: "food.jpg",
      title: "Đồ ăn",
      description: "Giao món đến tận tay bạn",
    },
    {
      img: "delivery.jpg",
      title: "Giao hàng",
      description: "Giao hàng giúp bạn",
    },
  ],
  [
    {
      img: "ship.jpg",
      title: "Trở thành đối tác giao hàng",
      description: "Giao hàng và tăng thu nhập, chủ động thời gian",
    },
  ],
  [
    {
      img: "shop.png",
      title: "Bán hàng trên ứng dụng",
      description:
        "Trở thành đối tác của chúng tôi để có thể tăng khả năng tiếp cận khách hàng",
    },
    {
      img: "delivery.jpg",
      title: "Giao hàng",
      description: "Giao hàng theo đơn đặt của bạn",
    },
  ],
];

const ServiceItem = ({ img, title, description }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        fontFamily: `'Inter', sans-serif `,
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          padding: 10,
          border: "3px solid green",
          borderRadius: 100,
          width: 70,
          height: 70,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={img} width={50} height={50} style={{ borderRadius: 100 }} />
      </div>
      <div style={{ flex: 1, padding: "0px 20px" }}>
        <h4>{title}</h4>
        <p> {description}</p>
      </div>
    </div>
  );
};

const Infomation = () => {
  const [state, setState] = useState(1);
  return (
    <div>
      <div className="info-container">
        <div
          className={`btn ${state == 1 && "btn-active"}`}
          onClick={() => setState(1)}
        >
          Người dùng
        </div>
        <div
          className={`btn ${state == 2 && "btn-active"}`}
          onClick={() => setState(2)}
        >
          Đối tác tài xế
        </div>
        <div
          className={`btn ${state == 3 && "btn-active"}`}
          onClick={() => setState(3)}
        >
          Đối tác thương nhân
        </div>
      </div>
      <div style={{ maxWidth: 500, padding: 10 }}>
        {_services[state - 1].map((i, index) => (
          <ServiceItem {...i} key={index} />
        ))}
      </div>
    </div>
  );
};
export default function Home() {
  return (
    <div>
      <div className={"container"}>
        <div className="left-container">
          <motion.div
            className="text-body-container"
            initial={{ opacity: 0.3, scale: 0.7 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-body text-red">Thiên Lôi</h1>
            <h1 className="text-body text-white">Delivery Service</h1>
          </motion.div>
          <p className="text-description">
            Từ những dịch vụ thiết yếu đến cơ hội gia tăng thu nhập. Tất cả đều
            có trên nền tảng của chúng tôi
          </p>
          <p
            className="text-description"
            style={{ fontWeight: "bold", textAlign: "left" }}
          >
            Hơn 180 cửa hàng đối tác
            <br />
            Hơn 40 tài xế đang hoạt động
          </p>
        </div>
        <div className="right-container">
          <img src="welcome.png" className="welcome-img" width={"414px"} />
        </div>
      </div>
      <div className="container" id="platform">
        <Infomation />
        <div className="right-container" style={{ flexWrap: "wrap" }}>
          <div>
            <div>
              <a
                target={"_blank"}
                href="https://play.google.com/store/apps/details?id=com.thienloi.app&pli=1"
                rel="noreferrer"
              >
                <img src="chplay.png" width={200} className="platform" />
              </a>
              <a
                href="https://testflight.apple.com/join/qJi603nJ"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src="apple.png" width={200} className="platform" />
              </a>
            </div>
            <div>
              <img
                src="app.png"
                width={350}
                style={{
                  filter: `drop-shadow(1px 1px 100px  green)`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container" id="contact">
        <div style={{ fontFamily: `'Inter', sans-serif`, padding: 10 }}>
          <h3>Thông tin liên hệ</h3>
          <h4>
            <strong> Địa chỉ </strong>: Tổ 5, Khu Bình Sơn, Phường Cẩm Sơn, Cẩm
            Phả, Quảng Ninh
          </h4>
          <h4>
            <strong> Telephone </strong>: +84 397307361
          </h4>
          <h4>
            <strong> Email </strong>: thienloi668@gmail.com
          </h4>
        </div>
        <div
          className="right-container"
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: 10,
          }}
        >
          <iframe
            width={"100%"}
            height={400}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d189066.71628735668!2d107.24776238285574!3d21.07740202713612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314b01c54bcb0c19%3A0xd54da45b7700afe5!2zVHAuIEPhuqltIFBo4bqjLCBRdeG6o25nIE5pbmgsIFZp4buHdCBOYW0!5e0!3m2!1svi!2ssg!4v1670981641943!5m2!1svi!2ssg"
            style={{ border: 0, borderRadius: 10 }}
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
      <div className="footer">
        <img
          src="logo.png"
          width={80}
          style={{ borderRadius: 10, marginTop: 10 }}
        />
        <h5>©Thiên Lôi 2022</h5>
      </div>
    </div>
  );
}
